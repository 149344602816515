import { permissionDepartments } from './../../../reducers/auth/permission.helper';
import { TeamType } from './../../../reducers/orm/team/team.model';
import { getTeams } from './../../../reducers/orm/team/team.service';
import { getShifts } from './../../../reducers/orm/shift/shift.service';
import { createSelector } from 'reselect';
import { OpenShiftModel } from '../../../reducers/orm/open-shift/open-shift.model';
import {
  getExchangesForSupervisorApproval,
  getIncomingExchangesForAuthenticatedUser,
} from '../../../reducers/orm/exchange/exchange.service';
import { ExchangeModel } from '../../../reducers/orm/exchange/exchange.model';
import { EnhancedAbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { getPendingAbsencesForSupervisor } from '../../../reducers/orm/absence/absence.service';
import { getOpenShiftsForAuthenticatedUserWithoutRejections } from '../../../reducers/orm/open-shift/open-shift.selector';
import { getPermissionState, hasPermission } from '../../../reducers/auth/permission.helper';
import { employeeActiveInPeriod, getAuthenticatedUser } from '../../../reducers/orm/employee/employee.service';
import { format } from '../../../shared/date.helper';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';

export const getMyOpenRequestsCounterWithoutRejections = createSelector(
  getOpenShiftsForAuthenticatedUserWithoutRejections,
  getIncomingExchangesForAuthenticatedUser,
  (openShifts: OpenShiftModel[], incomingExchanges: ExchangeModel[]): number =>
    openShifts.length + incomingExchanges.length
);

export const getSupervisorOpenRequestsCounter = createSelector(
  getExchangesForSupervisorApproval,
  getPendingAbsencesForSupervisor,
  (exchanges: ExchangeModel[], absences: EnhancedAbsenceModel[]): number => exchanges.length + absences.length
);

export const canClockPermission = createSelector(getPermissionState, getAuthenticatedUser, (permissionState, user) => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const activeState = employeeActiveInPeriod(today, today)(user);

  if (!activeState) {
    return false;
  }

  return hasPermission(
    {
      permissions: ['Clock time', 'Clock own time'],
      departments: 'any',
      userId: user.id,
    },
    permissionState
  );
});

export const showClockSwitch = createSelector(
  getPermissionState,
  getShifts,
  getAccountSubscription,
  getTeams,
  (permissionState, shifts, accountSubscription, teams) => {
    const clockDepartments = permissionDepartments(
      {
        permissions: ['Clock time', 'Clock own time'],
        userId: 'me',
        departments: 'any',
      },
      permissionState
    );

    const clockTeams = teams.filter(
      (team) =>
        team.type === TeamType.DEFAULT && clockDepartments.includes(team.department_id) && team.deleted === false
    );

    const clockShifts = shifts.filter((shift) => clockDepartments.includes(shift.department_id));

    const regularShiftCount = clockShifts.filter((shift) => shift.is_task === false && shift.deleted === false);

    const isAtLeastEarlyAdopter = hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, accountSubscription);

    return (
      isAtLeastEarlyAdopter &&
      !(regularShiftCount?.length === 1 && clockTeams?.length === 1 && clockDepartments?.length === 1)
    );
  }
);
