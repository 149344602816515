import { UnsafeAction as Action } from '../../interfaces';
import { LoadTimesheetsSuccessAction, TimesheetsLoadRequest } from './timesheet.model';

const ACTION_PREFIX = '[Timesheet]';

export const timesheetActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  BATCH_APPROVAL: `${ACTION_PREFIX} Batch approval`,
  BATCH_APPROVAL_SUCCESS: `${ACTION_PREFIX} Batch approval Success`,
  BATCH_APPROVAL_FAILED: `${ACTION_PREFIX} Batch approval Failed`,

  CLOCK_STATE_SUCCESS: `${ACTION_PREFIX} Clock state Success`,
  CLOCK_STATE_UPDATE_SUCCESS: `${ACTION_PREFIX} Clock state Update Success`,
  CLOCK_STATE_FAILED: `${ACTION_PREFIX} Clock state Failed`,
};

export class TimesheetAction {
  public static load(requestData): Action {
    return {
      type: timesheetActionType.LOAD,
      payload: requestData,
    };
  }

  public static loadSuccess(timesheet, requestData: TimesheetsLoadRequest): LoadTimesheetsSuccessAction {
    return {
      type: timesheetActionType.LOAD_SUCCESS,
      payload: timesheet,
      requestData,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: timesheetActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(timesheetData): Action {
    return {
      type: timesheetActionType.ADD,
      payload: timesheetData,
    };
  }

  public static addSuccess(timesheetResponse): Action {
    return {
      type: timesheetActionType.ADD_SUCCESS,
      payload: timesheetResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: timesheetActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(timesheetData): Action {
    return {
      type: timesheetActionType.UPDATE,
      payload: timesheetData,
    };
  }

  public static updateSuccess(timesheetResponse): Action {
    return {
      type: timesheetActionType.UPDATE_SUCCESS,
      payload: timesheetResponse,
    };
  }

  public static updateFailed(id, err): Action {
    return {
      type: timesheetActionType.UPDATE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static clockOut(timesheetId): Action {
    return {
      type: timesheetActionType.UPDATE,
      payload: { id: timesheetId },
    };
  }

  public static fetch(id: any) {
    return {
      type: timesheetActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: timesheetActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: timesheetActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  public static batchApprove(saveData: { id: string; status: 'Approved' | 'Declined' | 'Pending' }[]) {
    return {
      type: timesheetActionType.BATCH_APPROVAL,
      payload: saveData,
    };
  }

  public static batchApproveSuccess(response) {
    return {
      type: timesheetActionType.BATCH_APPROVAL_SUCCESS,
      payload: response,
    };
  }

  public static batchApproveFailed(saveData, err) {
    return {
      type: timesheetActionType.BATCH_APPROVAL_SUCCESS,
      payload: {
        data: saveData,
        err: err,
      },
    };
  }

  public static clockStateSuccess(response) {
    return {
      type: timesheetActionType.CLOCK_STATE_SUCCESS,
      payload: response,
    };
  }

  public static clockStateUpdateSuccess(response) {
    return {
      type: timesheetActionType.CLOCK_STATE_UPDATE_SUCCESS,
      payload: response,
    };
  }

  public static clockStateFailed() {
    return {
      type: timesheetActionType.CLOCK_STATE_FAILED,
    };
  }
}
