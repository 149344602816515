import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { UnsafeAction } from '../../interfaces';
import { RateCardModel } from '../rate-card/rate-card.model';
import { SharedModelState } from '../shared.model.state';
import { ShiftModel } from '../shift/shift.model';
import { TeamModel } from '../team/team.model';

export interface TimesheetState extends SharedModelState<TimesheetModel> {
  clockState?: ClockState;
}

export enum TimesheetStatus {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  PENDING = 'Pending',
}

// Only for translations
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const translations = [_('Approved'), _('Declined'), _('Pending')];

export interface TimesheetModel {
  id?: string;
  user_id: string;
  team_id: string;
  department_id: string;
  shift_id: string;
  date: string;
  starttime: string;
  clock: boolean;
  active_clock: boolean; // is shift currently clocked in?
  clocked_in: string;
  clocked_in_latitude: string;
  clocked_in_longitude: string;
  clocked_in_accuracy: string;
  clocked_in_origin: string;
  endtime: string;
  clocked_out: string;
  clocked_out_latitude: string;
  clocked_out_longitude: string;
  clocked_out_accuracy: string;
  clocked_out_origin: string;
  clocked_break: string;
  total: string;
  salary: string;
  status: TimesheetStatus;
  roster_id: string;
  break: number;
  meals: number;
  coc?: string;
  kilometers: number;
  note: string;
  rate_card_id: string;
  created_by: string;
  modified_by: string;
  reviewed_by: string;
  loading?: boolean;
  deleted?: boolean;
  saving?: boolean;
  ClockBreak?: [ClockBreak];
  Team: TeamModel;
  Shift: ShiftModel;
  Rates?: RateModel;
  RateCard?: RateCardModel;
  department_name?: string;
  location_name?: string;
  custom_fields?: any;
  [propName: string]: any; // allow for custom properties
}

export interface TimesheetModelEnchanced extends TimesheetModel {
  closed: boolean;
  canApprove: boolean;
  canViewTimesheetModal: boolean;
  canEdit: boolean;
  canClockOut: boolean;
}

export interface ClockBreak {
  clocked_in: string;
  clocked_in_latitude: string;
  clocked_in_longitude: string;
  clocked_in_accuracy: string;
  clocked_out: string;
  clocked_out_latitude: string;
  clocked_out_longitude: string;
  clocked_out_accuracy: string;
}

export interface RateModel {
  duration: number;
  surcharge_pay: number;
  surcharge_time: number;
  worked_for_vacation: number;
  RateBlock: [RateBlockModel];
}

export interface RateBlockModel {
  date: string;
  starttime: string;
  endtime: string;
  duration: number;
  paid_percentage: string;
  paid_percentage_id: string;
  time_percentage: string;
  time_percentage_id: string;
  break: number;
  surcharge_pay: number;
  surcharge_time: number;
  is_overtime?: boolean;
}

export interface ClockState {
  active_clock?: boolean;
  ClockBreak?: string[];
  Team?: TeamModel;
  Timesheet?: TimesheetModel;
  nearest?: ClockNearest;
}

export interface ClockNearest {
  break: string;
  date: string;
  department_id: string;
  roster_id: string;
  shift_id: string;
  starttime: string;
  team_id: string;
  user_id: string;
}

export interface TimesheetsLoadRequest {
  userId?: string;
  minDate?: string;
  maxDate?: string;
  status?: string;
  departmentId?: string | string[];
  rates?: boolean;
}

export interface TimesheetStatementRequest {
  employeeIds: string[];
  from: string;
  to: string;
}

export interface LoadTimesheetsSuccessAction extends UnsafeAction {
  requestData: TimesheetsLoadRequest;
}

export enum TimeSheetClockActionType {
  SWITCH_SHIFT = 'SWITCH_SHIFT',
  CLOCK_OUT = 'CLOCK_OUT',
  CLOCK_IN = 'CLOCK_IN',
}

export enum TimeSheetClockAuthorizationLevel {
  SUPERVISOR = 'SUPERVISOR',
  APPLICATION = 'APPLICATION',
  TERMINAL = 'TERMINAL',
}
