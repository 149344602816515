import { Injectable } from '@angular/core';
import { Features } from '@app/enums';
import omit from 'lodash-es/omit';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { TimesheetSchema } from '../../shared/schemas';
import { FeatureService } from './../../../startup/feature.service';
import {
  TimeSheetClockActionType,
  TimeSheetClockAuthorizationLevel,
  TimesheetModel,
  TimesheetsLoadRequest,
  TimesheetStatementRequest,
} from './timesheet.model';

interface breakResponse {
  break: false | string;
}

interface checkRateCardResponse {
  rate_card_id: false | string;
}

@Injectable()
export class TimesheetApi {
  private endpoint = 'timesheets/';
  public constructor(
    private gateway: ApiGateway,
    private featureService: FeatureService,
  ) {}

  public load(requestData: TimesheetsLoadRequest, dispatchStart?: Action): Observable<any> {
    const searchParams = {
      ...requestData,
      include: 'ClockBreak',
    };

    const search = objectToSearchParams(searchParams, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('Timesheet', res)),
        map((res) => res.map((timesheet) => cleanTimesheet(timesheet))),
        map((data) => normalize(data, arrayOf(TimesheetSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(timesheetData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, timesheetData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Timesheet', res)),
      map((data) => normalize(data, TimesheetSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Timesheet', res)),
      map((data) => normalize(data, TimesheetSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public batchUpdate(batch, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch', batch, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Timesheet', res)),
      map((res) => res.map((timesheet) => cleanTimesheet(timesheet))),
      map((data) => normalize(data, arrayOf(TimesheetSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(timesheetId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + timesheetId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Timesheet', res)),
      map((data) => normalize(data, TimesheetSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public checkBreak(timesheetData): Observable<breakResponse> {
    return this.gateway.post(this.endpoint + 'check/break', timesheetData);
  }

  public checkRateCard(timesheetData): Observable<checkRateCardResponse> {
    return this.gateway.post(this.endpoint + 'check/rate_card', timesheetData);
  }

  public checkTotal(timesheetData): Observable<TimesheetModel> {
    return this.gateway
      .post(this.endpoint + 'check/total', timesheetData)
      .pipe(map((res) => reformatEntityResponse('Timesheet', res)));
  }

  public clock(
    timesheetData,
    action: TimeSheetClockActionType,
    authLevel: TimeSheetClockAuthorizationLevel = TimeSheetClockAuthorizationLevel.APPLICATION,
    dispatchStart?: Action,
  ) {
    const postData = {
      Timesheet: timesheetData,
      action: action,
      authorization_level: authLevel,
    };

    return this.gateway.post(this.endpoint + 'clock', postData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Timesheet', res)),
      map((data) => normalize(data, TimesheetSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public clockState(userId, dispatchStart?: Action) {
    return this.gateway.get(`${this.endpoint}clock/${userId}`, undefined, dispatchStart);
  }

  public saveClock(
    clockData,
    action: TimeSheetClockActionType,
    authLevel: TimeSheetClockAuthorizationLevel = TimeSheetClockAuthorizationLevel.APPLICATION,
    dispatchStart?: Action,
  ) {
    const postData = {
      Timesheet: clockData,
      action: action,
      authorization_level: authLevel,
    };

    return this.gateway.post(this.endpoint + 'clock', postData, undefined, dispatchStart);
  }

  public statement(requestData: TimesheetStatementRequest, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(`${this.endpoint}statement`, requestData, dispatchStart);
  }
}

function cleanTimesheet(timesheet) {
  // Handle empty array response from backend for empty custom_fields
  if (Array.isArray(timesheet.custom_fields) && timesheet.custom_fields.length === 0) {
    timesheet.custom_fields = {};
  }

  if (!timesheet.Roster) {
    return timesheet;
  }

  return omit(timesheet, 'Roster');
}
